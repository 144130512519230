import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "../../components/gatsby-image";
import SocialProfile from "../../components/social-profile/social-profile";
import { IoLogoTwitter, IoLogoLinkedin, IoLogoGithub } from "react-icons/io";
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style";

const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/vchyzhevskyi",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoLinkedin />,
    url: "https://www.linkedin.com/in/vchyzhevskyi/",
    tooltip: "Linked In",
  },
  {
    icon: <IoLogoGithub />,
    url: "https://github.com/vchyzhevskyi",
    tooltip: "GitHub",
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Me</h2>
        <p>
          I'm Władysław Czyżewski. I'm software engineering consultant based in
          Warsaw, Poland.
        </p>
      </AboutPageTitle>

      <AboutImage>
        <GatsbyImage
          src={Data.avatar.childImageSharp.gatsbyImageData}
          alt="about"
        />
      </AboutImage>

      <AboutDetails>
        <h2>Intro</h2>
        <p>
          My journey starts back in the mid-2000s, when grass was greener,
          Facebook didn't exist, and weird dial-up modem connection sounds could
          still be heard in some houses. Since the very first line of code, I
          continuously develop my skill to solve IT problems that fit all
          requirements of stakeholders – not only as a software engineer but
          also as a business partner to propose the best available solution.
        </p>
        <p>
          Along my journey in IT, I have worked in more or less complicated
          projects with .NET (Framework, Core), JavaScript (NodeJS, React, React
          Native), Python, RDBs (MySQL, SQL Server, PostgreSQL), NoSQL, Cloud
          (AWS mostly, Azure, recently discovering features of OVH cloud
          platform) – this is a shortlist of keywords for my more than 8 years
          commercial experience. I would say – I'm a platform and technology
          agnostic evangelist, my commercial and non-commercial experience makes
          me feel, that every single project requires a unique approach and
          unique toolset and my favorite answer – "it depends".
        </p>
        <p>
          During my studies on UITM in Rzeszów (Poland), I was also participated
          in the university's .NET User Group – both as a listener and as a
          mentor for younger students. In my free time I'm sharing my knowledge
          on my personal blog.
        </p>
        <p>The client's voices:</p>
        <p>
          … I had a chance to review the codebase, which I haven’t done for some
          time. I wanted to say – great work! It’s looking very well maintained.
          I’m very pleased to see the Unit test coverage – nice work … – Lead
          Delivery Manager, Liverpool-based small-sized company
        </p>
        <p>
          … you have added a lot of value by understanding the model logic,
          checking and validating the solution we are implementing for it’s
          accuracy against the intended model i.e. it’s your logical and precise
          brain which was and it is the greatest asset for us in this project …
          – CTO, London-based medium-sized company
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
